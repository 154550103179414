const Columns = [
  // { id: "checkbox", numeric: true, label: "", isSortable: false },
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Issue #",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    isSortable: true,
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client name",
    isSortable: true,
  },
  {
    id: "openedOn",
    numeric: false,
    disablePadding: false,
    label: "Open Date",
    isSortable: true,
  },
  {
    id: "closedOn",
    numeric: false,
    disablePadding: false,
    label: "Close Date",
    isSortable: true,
  },  
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },  
  // {
  //   id: "action",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Action",
  //   rowclassName: "text-right",
  //   className: "text-right pr-30",
  // },
];

const Rows = [
  {
    issueId: '#00092',
    employeeName: 'Smith Thomas',
    clientName: 'MTA',
    openDate: 'June 15, 2021',
    closeDate: 'June 17, 2021',
    priority: 'High'
  },
  {
    issueId: '#00093',
    employeeName: 'Johnson',
    clientName: 'MTA',
    openDate: 'June 15, 2021',
    closeDate: 'June 17, 2021',
    priority: 'Low'
  },
  {
    issueId: '#00094',
    employeeName: 'Williams',
    clientName: 'AIRBNB',
    openDate: 'June 15, 2021',
    closeDate: 'June 17, 2021',
    priority: 'High'
  },
  {
    issueId: '#00095',
    employeeName: 'David',
    clientName: 'MTA',
    openDate: 'June 15, 2021',
    closeDate: 'June 17, 2021',
    priority: 'Low'
  },
  {
    issueId: '#00092',
    employeeName: 'Smith Thomas',
    clientName: 'MTA',
    openDate: 'June 15, 2021',
    closeDate: 'June 17, 2021',
    priority: 'High'
  },
  {
    issueId: '#00092',
    employeeName: 'Smith Thomas',
    clientName: 'MTA',
    openDate: 'June 15, 2021',
    closeDate: 'June 17, 2021',
    priority: 'High'
  },
  {
    issueId: '#00092',
    employeeName: 'Smith Thomas',
    clientName: 'MTA',
    openDate: 'June 15, 2021',
    closeDate: 'June 17, 2021',
    priority: 'High'
  },
];

export { Columns, Rows };
