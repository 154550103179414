import React, { useRef } from "react";
import { Button } from "@material-ui/core";

const FileUploadComponent = (props) => {
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    props?.onuploadfile(file);
  };

  return (
    <div className="file-uploader-wrap">
      <input
        ref={fileInput}
        type="file"
        onChange={handleFileInput}
        style={{ display: "none" }}
      />
      <Button
        variant="contained"
        onClick={(e) => fileInput.current && fileInput.current.click()}
        className="flatbutton themebutton"
      >
        {props?.buttonText ? props?.buttonText : "Browse"}
      </Button>
    </div>
  );
};

export default FileUploadComponent;
