import React from "react";

// Creating the context object and passing the default values.
const AuthContext = React.createContext({
  status: false,
  currentChannel: null,
  setCurrentChannel: () => {},
  isReadPrivate: true,
  isReadPublic: true,
  setIsReadPrivate: () => {},
  setIsReadPublic: () => {},
  userProfile: {},
  supplieStatusCount: {OPEN:0, Pending: 0},
  pendingSupplyStatus: {},
  login: () => {},
  logoutuser: () => {},
  isLoading: false
});

export default AuthContext;
