const Columns = [
  // { id: "checkbox", numeric: true, label: "", isSortable: false },
  {
    id: "categories",
    numeric: false,
    disablePadding: true,
    label: "Categories",
    isSortable: true,
  },
  {
    id: "supplies",
    numeric: false,
    disablePadding: false,
    label: "Suplies",
    isSortable: true,
  },

  {
    id: "deliveryDate",
    numeric: false,
    disablePadding: false,
    label: "Est. Delivery Date",
    isSortable: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSortable: true,
    
  },
];

const Rows = [];

export { Columns, Rows };
