import { firebase } from "../firebase-config";
import { BaseUrl, COLLECTIONS } from "../config";
import axiosInstance from "../axios";
import { getLoginToken } from "../storage";
const collection = COLLECTIONS.NOTIFICATIONS;
/**
 * Get Tasks list from standardtasks collection (shown while create client)
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getNotificationList(onSuccess, onError) {
  firebase
    .firestore()
    .collection(collection)
    .orderBy("createdOn", "desc")
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });

      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      onError && onError(error);
    });
}
/**
 * send notification and save it to database
 * @param {*} formData : data to be saved and sent as notification
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function saveNotification(formData, onSuccess, onError) {
  // let currentUserId = firebase.auth().currentUser.uid;
  // console.log(formData);
  firebase
    .firestore()
    .collection(collection)
    .add({
      ...formData,
      // createdBy: currentUserId,
      createdOn: new Date().getTime(),
    })
    .then(
      async (doc) => {
        const docdata = formData;
        docdata.id = doc.id;

        let emailList = formData.Employee.map((item) => {
          return item.email;
        });
        let phoneList = formData.Employee.map((item) => {
          return item.phone;
        });

        let userIds = formData.Employee.map((item) => {
          return item.id;
        });
        if (formData.Type === "PushNotification") {
          const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };

          let frmPush = {
            userIds: userIds,
            title: "ProTeams - Alert",
            textMessage: formData.Message,
            extData: { type: "Messages" },
          };
          await axiosInstance.post(
            `${BaseUrl}sendPushNotifications`,
            { ...frmPush },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        } else if (formData.Type === "Email") {
          const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
          await axiosInstance.post(
            `${BaseUrl}sendEmailData`,
            {
              email: emailList,
              subject: `VBS team message`,
              contenttxt: `<p>VBS Team Message</p><p></p>
              ${formData.Message}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        }
        if (formData.Type === "Push") {
          const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
          await axiosInstance.post(
            `${BaseUrl}sendSmsText`,
            {
              phoneNumber: phoneList,
              textMessage: `ProTeams Message\n\n${formData.Message}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        }
        if (formData.Type === "Both") {
          const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
          await axiosInstance.post(
            `${BaseUrl}sendEmailData`,
            {
              email: emailList,
              subject: `VBS team message`,
              contenttxt:
                '<p>VBS Team Message</p><p></p><div style="white-space:pre-wrap;">' +
                formData.Message +
                "</div>",
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
          await axiosInstance.post(
            `${BaseUrl}sendSmsText`,
            {
              phoneNumber: phoneList,
              textMessage: `ProTeams Message\n\n${formData.Message}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );

          let frmPush = {
            userIds: userIds,
            title: "ProTeams",
            textMessage:
              formData.Message.length > 150
                ? `${formData.Message.substring(0, 140)}...`
                : formData.Message,
            extData: { type: "Messages" },
          };
          await axiosInstance.post(
            `${BaseUrl}sendPushNotifications`,
            { ...frmPush },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        }

        onSuccess && onSuccess({ status: true, data: docdata });
      },
      (error) => {
        console.log(`ERROR: ${collection} ${error}`);
        onError({ status: false, error: error });
      }
    )
    .catch((e) => {
      console.log(`ERROR: ${collection} ${e}`);
      onError({ status: false, error: e });
    });
}
