import React, { useEffect, useState } from "react";
import { getFormatedDate } from "../../services/helpers";

const NotificationDetails = (props) => {
  const [itemdata, setItemdata] = useState({});

  useEffect(() => {
    setItemdata(props.item);
  }, [props.item]);
  const emplist = itemdata?.Employee ? itemdata?.Employee?.map((eitem) => eitem.name) : [];
  const empliststring = emplist.join(', ')
  return (
    <div className="p-15">
      <p>
        <strong>date: </strong> {getFormatedDate(itemdata?.createdOn)}
      </p>
      <p>
        <strong>group: </strong> {itemdata?.Group?.title}
      </p>
      <p>
        <strong>method: </strong> {itemdata?.Type}
      </p>
      <p>
        <strong>client: </strong> {itemdata?.Client?.name}
      </p>
      <p>
        <strong>message: </strong> <div className="nl2br">{itemdata?.Message}</div>
      </p>
      <p>
        <strong>Employees / Receipents: </strong> {empliststring}
      </p>
      
    </div>
  );
};
export default NotificationDetails;
