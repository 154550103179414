import React, {useContext, useEffect} from "react";
import { List, ListItem, IconButton, Badge } from "@material-ui/core";
import { NavLink, Link, useLocation  } from "react-router-dom";
import { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import AuthContext from "../../hooks/authContext";

const ChildMenuItem = (props) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const { item, index } = props;
  const [showChild, setShowChild] = useState(false);
  const hasinnerChild =
    typeof item?.childItems !== "undefined" && item?.childItems?.length > 0
      ? true
      : false;
  const showChildMenu = (e) => {
    e.preventDefault();
    setShowChild(!showChild);
  };
                  
  let isShowBadge = false;
  if(item.to === '/message-board/live-chat') {
      if(!auth.isReadPrivate || !auth.isReadPublic) {
        isShowBadge = true;
      }
  }
  if(item.to === '/supplies/cleaning/review') {
    if(auth.pendingSupplyStatus.length > 0) {
      isShowBadge = true;
    }
  }

  const renderMenuChilditem = (item) => {
    return (
      showChild === true && (
        <List key={"subitem" + item} className="submenuitems">
          {item?.childItems?.map((child, index) => {
            return <ChildMenuItem item={child} index={index} />;
          })}
        </List>
      )
    );
  };

  useEffect(() => {
    if (location.pathname === '/supplies/cleaning/review') {
      auth.pendingSupplyStatus.length = 0;
    }
  }, [location.pathname, auth]);

  return (
    <ListItem key={item.label + index}>
      {hasinnerChild === true ? (
        <>
          <div className="innerChildItemTitle">
            <Link to={item.to} className={item?.classes ? item?.classes : ""}>
              {item?.icon && item.icon} {item.label}
            </Link>
            <IconButton size="small" onClick={showChildMenu}>
              {showChild === true ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
          </div>
          {renderMenuChilditem(item)}
        </>
      ) : (
        <NavLink
          className={`${item?.classes ? item?.classes : ""}`}
          to={item.to}
        >
          {item?.icon && item.icon} {item.label} {`${item.isCountKey ? ' (' + auth.supplieStatusCount[item.isCountKey] + ')':'' }`} {isShowBadge &&  <Badge color="secondary" variant="dot" className="ChildMenuBadgeIcon" />}
        </NavLink>
      )}
    </ListItem>
  );
};

export default ChildMenuItem;
