/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { TablePagination } from "@material-ui/core";
import { getComparator, stableSort } from "../../services/helpers";
import ThemeTableRow from "./ThemeTableRow";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import styles from "./Table.module.scss"

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
const ThemeTable = (props) => {
  const [state, setState] = useState({
    order: "asc",
    orderBy: "",
    page: 0,
    rowsPerPage: 10,
  });

  useEffect(() => {
    setState({ ...state, page: 0 });
  }, [props, props?.filterBy]);

  const createSortHandler = (e, property) => {
    const { order, orderBy } = state;
    const isAsc = orderBy === property && order === "asc";
    setState({ ...state, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleChangePage = (event, newPage) => {
    setState({ ...state, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    let size = parseInt(event.target.value, 10);
    setState({ ...state, page: 0, rowsPerPage: size });
  };

  const { rows, headCells, hidePagination } = props;
  let rowsList = rows?.length > 0 ? rows : [];
  const { order, orderBy, page, rowsPerPage } = state;

  const finalData = !hidePagination
    ? stableSort(rowsList, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    : stableSort(rowsList, getComparator(order, orderBy));

  return (
    <>
      <Table
        id={props.id ? props.id : "MyThemeTable"}
        className={props.supplies !== true ? `themeTable sizesmall pt-0 noborder ` + `${(props.noBorder === true) ? 'noBorder' : ''}` :
          `customtable sizesmall pt-0 noborder ` + `${(props.noBorder === true) ? 'noBorder' : ''}`}>
        {props.extraHeader && <thead >
          <tr>
            <td ></td>
            <td ></td>
            <td ></td>
            <td className={` ${styles.taskassigned}`} colSpan={3} >Task Assigned</td>
            <td ></td>
          </tr>
        </thead>}
        <Thead>

          <Tr>
            {headCells?.map((hcellItem, index) => (
              <Th
                key={"headCell" + index}
                className={`${hcellItem?.isSortable ? "isSortable" : ""} ${orderBy === hcellItem.id ? "sortby" : ""
                  } ${hcellItem?.className ? hcellItem?.className : ""}`}
                onClick={(e) =>
                  hcellItem?.isSortable && createSortHandler(e, hcellItem.id)
                }
              >
                {hcellItem.label}
                {hcellItem?.isSortable && orderBy === hcellItem.id ? (
                  order === "desc" ? (
                    <ArrowDownwardIcon className="sortIcon" />
                  ) : (
                    <ArrowUpwardIcon className="sortIcon" />
                  )
                ) : null}
              </Th>
            ))}
            {/* {headCells?.map((hcellItem, index) => (
              <ThemeTableHeadRow
                key={"themetablerowhead" + index}
                {...props}
                hcellItem={hcellItem}
                index={index}
                createSortHandler={createSortHandler}
                order={order}
                orderBy={orderBy}
              />
            ))} */}
          </Tr>
        </Thead>
        <Tbody>
          {props?.isLoading === true ? (
            <Tr>
              <Td colSpan={headCells?.length} className="no_rec_td">
                Loading...
              </Td>
            </Tr>
          ) : finalData?.length > 0 ? (
            finalData?.map((rcellItem, rindex) => (
              <ThemeTableRow
                key={"themetablerow" + rindex}
                {...props}
                rcellItem={rcellItem}
                rindex={rindex}
                headCells={headCells}
                divider={(rindex !== (finalData.length - 1) && props.divider) ? props.divider : ""}
              />
            ))
          ) : (
            <Tr>
              <Td colSpan={headCells?.length} className="no_rec_td">
                No Records Found
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {!hidePagination && rows?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
export default ThemeTable;
