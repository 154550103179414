import React, { useContext, useEffect, useState } from "react";
import { Container, IconButton, Popover, Tooltip, Typography } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../components/Table/ThemeTable";
import { getClientsListbasedonIds } from "../../../services/apiservices/client";
import { getEmployeesListBasedOnId } from "../../../services/apiservices/employee";
import { getSupplyOrderList, updateArchive } from "../../../services/apiservices/supplies";
import CommonContext from "../../../hooks/commonContext";
import { getFormatedDate, groupByKey } from "../../../services/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import TableToolbar from "../../../components/Table/TableToolbar";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useNavigate } from "react-router";


const CustomTooltip = ({ listData, title }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <Typography
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                style={{
                    cursor: "pointer",
                    color: "blue",
                }}
            >{title}
            </Typography>
            <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <div className="tooltip-heading2 p-5">
                    <div className="tooltip-container2">
                        {Array.isArray(listData) ? (
                            <div>
                                {listData.map((item, index) => (
                                    <div key={index}>
                                        <div>{item.title} ({item.qty})</div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div>{listData}</div>
                        )}
                    </div>
                </div>
            </Popover>
        </div>
    );
};


const ArchiveSupplies = (props) => {
    const commonContext = useContext(CommonContext);
    const [allSupplies, setAllSupplies] = React.useState([]);
    const [allClient, setAllClients] = React.useState([]);
    const [allPriority, setAllPriority] = React.useState([]);
    const [allDate, setAllDate] = React.useState([]);
    const [selectedPriority, setSelectedPriority] = useState("any");
    const [filter, setFilterValues] = React.useState({
        priority: "any",
        client: "any",
        date: "any",
    });
    const history = useNavigate();
    const getColumns = [
        {
            id: "orderId",
            numeric: false,
            disablePadding: true,
            label: "Order Id",
            isSortable: true,
        },
        {
            id: "dateOfRequest",
            numeric: false,
            disablePadding: false,
            label: "Order Date",
            isSortable: true,
        },
        {
            id: "employeeName",
            numeric: false,
            disablePadding: true,
            label: "Employee Name",
            isSortable: true,
        },
        {
            id: "clientName",
            numeric: false,
            disablePadding: false,
            label: "Client Name",
            isSortable: true,
        },
        {
            id: "supplies",
            numeric: false,
            disablePadding: true,
            label: "Supplies",
            isSortable: true,
        },
        {
            id: "priority",
            numeric: false,
            disablePadding: false,
            label: "Priority",
            isSortable: true,
        },
        {
            id: "deliverStatus",
            numeric: false,
            disablePadding: false,
            label: "Deliver Status",
            isSortable: true,
        },
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Action",
            isSortable: true,
        }
    ];



    const manageDataList = (newRows) => {
        // set data for filter fields
        let allpriority = groupByKey(newRows, "priority");
        let allpriorityOptions = Object.keys(allpriority)?.map((acitem) => {
            return { label: acitem, value: acitem };
        });
        let finalSetAllPriority = allpriorityOptions ? allpriorityOptions : [];
        setAllPriority([...finalSetAllPriority]);

        let allclients = groupByKey(newRows, "clientName");
        let allclientOptions = Object.keys(allclients)?.map((acitem) => {
            return { label: acitem, value: acitem };
        });
        let finalSetAllClient = allclientOptions ? allclientOptions : [];
        setAllClients([...finalSetAllClient]);

        let allDates = groupByKey(newRows, "dateOfRequest");
        let allDateOptions = Object.keys(allDates)?.map((aacitem) => {
            return { label: getFormatedDate(aacitem), value: aacitem };
        });
        let finalSetAllDate = allDateOptions ? allDateOptions : [];
        setAllDate([...finalSetAllDate]);
    };


    const fetchSuppliesList = async () => {
        await getSupplyOrderList(
            {
                status: ["Approved", "Rejected"],
            },
            async (res) => {
                if (res) {
                    let filterOrders = res.filter(item => item.archive === true);

                    let clientIds = filterOrders.map((ordItem) => ordItem.clientId);
                    clientIds = [...new Set(clientIds)]

                    let empIds = filterOrders.map((orderItem) => orderItem.createdBy);
                    empIds = [...new Set(empIds)]

                    let clintsData = [];
                    if (clientIds.length > 0) {
                        await getClientsListbasedonIds(clientIds, (result) => {
                            const clients = result.map((item) => {
                                return { value: item?.id, label: item?.clientName };
                            });
                            clintsData = clients;
                        });
                    }

                    let employeeData = [];
                    if (empIds.length > 0) {
                        await getEmployeesListBasedOnId(empIds, (result) => {
                            const employees = result.map((item) => {
                                return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}` };
                            });
                            employeeData = employees;
                        });
                    }
                    let newRows = filterOrders.map((ritem) => {
                        let totalQuantity = 0;

                        const client = clintsData.find(client => client?.value === ritem?.clientId);
                        const clientName = client ? client.label : 'Unknown Client';

                        const employee = employeeData.find(emp => emp?.value === ritem?.createdBy);
                        const employeeName = employee ? employee.label : 'Unknown employee';

                        if (ritem.supplyItems) {
                            ritem.supplyItems.forEach((item) => {
                                totalQuantity += item.qty || 0;
                            });
                        }
                        return {
                            ...ritem,
                            orderId: String(ritem.orderNumber).padStart(6, '0'),
                            supplies: `(${totalQuantity})`,
                            employeeName: employeeName,
                            clientName: clientName,
                        };
                    });

                    let sortedData = sortingFunction(newRows);
                    await manageDataList(sortedData);
                    await setAllSupplies(sortedData);
                    commonContext?.setLoader(false);
                } else {
                    commonContext?.setLoader(false);
                }
            },
            (resError) => {
                console.error("reserror", resError);
                commonContext?.setLoader(false);
            }
        );
        commonContext?.setLoader(true);
    };


    useEffect(() => {
        fetchSuppliesList();
    }, []);

    const sortingFunction = (data) => {
        const result = data.sort((a, b) => {
            if (a.orderId < b.orderId) return -1;
            if (a.orderId > b.orderId) return 1;

            if (a.employeeName < b.employeeName) return -1;
            if (a.employeeName > b.employeeName) return 1;

            return 0;
        });

        return result;
    };


    const priorityOptions = [
        { label: "All", value: "any" },
        { label: "High", value: "1" },
        { label: "Medium", value: "2" },
        { label: "Low", value: "3" }
    ];

    const handlePriorityChange = (e) => {
        const value = e.target.value;
        setSelectedPriority(value);
        setFilterValues(prev => ({ ...prev, priority: value }));
    };


    const setFilter = (name, value) => {
        setFilterValues({ ...filter, [name]: value });
    };

    const tableRightComponent = () => {
        return (
            <div className="d-flex flex-center groupsearch editEmp issueAlign">
                <div className="d-flex flex-center w-100 mv-7">
                    <label className="mh-12">Priority</label>
                    <SingleSelect
                        value={selectedPriority}
                        className={`field w-60`}
                        name={"Priority"}
                        onChange={handlePriorityChange}
                        options={priorityOptions}
                    />
                </div>
                <div className="d-flex flex-center mh-12 w-100 mv-7">
                    <label className="mh-12">Client</label>
                    <SingleSelect
                        // label="Client"
                        value={filter?.client ? filter?.client : "any"}
                        className={`field w-60 ddminWidths`}
                        name={"client"}
                        onChange={(e) => {
                            setFilter("client", e?.target?.value);
                        }}
                        options={
                            allClient
                                ? [{ label: "Any", value: "any" }, ...allClient]
                                : [{ label: "Any", value: "any" }]
                        }
                    />
                </div>
                <div className="d-flex flex-center w-100 mv-7">
                    <label className="mr-18 ml-12"> Date </label>
                    <SingleSelect
                        // label="Date"
                        value={filter?.date ? filter?.date : "any"}
                        className={`field w-60 ddminWidths`}
                        name={"date"}
                        onChange={(e) => {
                            setFilter("date", e?.target?.value);
                        }}
                        options={
                            allDate
                                ? [{ label: "Any", value: "any" }, ...allDate]
                                : [{ label: "Any", value: "any" }]
                        }
                    />
                </div>
            </div>
        )
    }

    const redirectToDetails = (id) => {
        if (typeof id !== "undefined" && id !== "")
            history("/supplies/cleaning/archives/" + id);
    };

    const handelUnArchive = async (id) => {
        const formData = {
            archive: false
        }
        await updateArchive(id, formData);
        await fetchSuppliesList();
    }

    const getDeliveryStatus = (supplyItems) => {
        if (!supplyItems || !Array.isArray(supplyItems)) {
          return 'Invalid Supplies';
        }
      
        const statuses = supplyItems.map(item => item.status);
      
        const hasDelivered = statuses.includes('Delivered');
        const hasShipped = statuses.includes('Shipped');
        const hasOrdered = statuses.includes('Ordered');
        const hasBackOrdered = statuses.includes('Back Ordered');
        const hasCancelItem = statuses.includes('Cancel Item');
      
        if (statuses.every(status => status === 'Delivered') || (hasDelivered && hasCancelItem && !hasOrdered && !hasBackOrdered && !hasShipped)) {
          return 'Full Delivery';
        }
        if (statuses.every(status => status === 'Cancel Item')) {
          return 'Full Delivery';
        }
        if (hasDelivered && (hasShipped || hasOrdered || hasBackOrdered || hasCancelItem)) {
          return 'Partial Delivery';
        }
        if (statuses.every(status => status === 'Shipped') || (hasShipped && hasCancelItem && !hasDelivered && !hasOrdered && !hasBackOrdered)) {
          return 'Full Shipment';
        }
        if (hasShipped && (hasOrdered || hasBackOrdered) && !hasDelivered) {
          return 'Partial Shipment';
        }
        if (hasOrdered && hasCancelItem) {
          return 'In Process';
        }
        if (statuses.every(status => status === 'Ordered')) {
          return 'In Process';
        }
        if (statuses.every(status => status === 'Back Ordered')) {
          return 'Back Ordered';
        }
        if (hasOrdered && hasBackOrdered) {
          return 'In Process';
        }
        return 'In Process';
      };

    const getPriorityLabel = (priority) => {
        switch (priority) {
            case "1":
                return "High";
            case "2":
                return "Medium";
            case "3":
                return "Low";
            default:
                return "Unknown";
        }
    };

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "supplies":
                return (
                    <>
                        <CustomTooltip
                            listData={rcellItem?.supplyItems}
                            title={`${rcellItem?.supplies}`}
                        />
                    </>
                )
                break;
            case "dateOfRequest":
                return getFormatedDate(rcellItem?.dateOfRequest);
            case "deliverStatus":
                return <>{getDeliveryStatus(rcellItem?.supplyItems)}</>;
                break;
            case "priority":
                return <>{getPriorityLabel(rcellItem?.priority)}</>;
                break;
            case "action":
                return (
                    <>
                        <div>
                            <Tooltip title="UnArchive">
                                <UnarchiveIcon className="color-primary" onClick={() => handelUnArchive(rcellItem?.id)} />
                            </Tooltip>
                            <VisibilityIcon className="ml-20 color-primary" onClick={() => redirectToDetails(rcellItem?.id)} />
                        </div>
                    </>
                );
                break;
            default:
                return rcellItem[hcellItem?.id];
        }
    };

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Supplies"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Archive Supplies Request"}
                />

                <div className={`sectionBox`}>
                    <FontAwesomeIcon icon={faFilter} />
                    <TableToolbar
                        title=""
                        rightComponent={tableRightComponent()}
                    />
                    <ThemeTable
                        rows={allSupplies}
                        headCells={getColumns}
                        hidePagination={true}
                        renderRowCell={renderRowCell}
                    />
                </div>
            </Container>
        </>
    );
}

export default ArchiveSupplies;