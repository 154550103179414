import React from "react";
// import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Toolbar, Typography } from "@material-ui/core";
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    // paddingRight: 0,
    borderBottom: "1px solid #000",
    borderBottomColor: lighten(theme.palette.primary.main, 0.85),
    minHeight: "auto",
    paddingBottom: "10px",
    [theme.breakpoints.between(0, 940)]: {
      flexDirection:"column",
      alignItems:"flex-start",
      border:"none"
    },
  },
  removeBorder: {
    border: "none"
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  toolBarRight: {
    marginLeft: "auto",
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  // const { numSelected } = props;

  return (
    <Toolbar className={`${clsx(classes.root)} ${props.removeBorder && clsx(classes.removeBorder)} `}>
      {props?.title !== "" && (
        <Typography
          className={`${classes.title} tableTitle`}
          variant={props?.titlevariant ? props?.titlevariant : "h6"}
          id="tableTitle"
          component="div"
        >
          {props?.title}
        </Typography>
      )}
      <div className={`${classes.toolBarLeft}`}>
        {props?.leftComponent && props?.leftComponent}
      </div>
      <div className={`${classes.toolBarRight}`}>
        {props?.rightComponent && props?.rightComponent}
      </div>
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};

export default TableToolbar;
