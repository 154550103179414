import React, { useState, useContext } from "react";
import { AppBar, Link, Toolbar, Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";

import AuthContext from "../hooks/authContext";
import CommonContext from "../hooks/commonContext";

import Footer from "../components/footer/footer";
import Loader from "../components/AppLoader/Loader";
import csmWhiteText from "../assets/images/csmWhiteText.png";

const drawerWidth = "100%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: "#414855",
    minHeight: "auto",
  },
  toolbarmirror: { ...theme.mixins.toolbar, backgroundColor: "transparent" },
  drawerPaper: {
    // overflow: 'hidden',
    width: drawerWidth,
    border: "none",
    boxShadow: "1px 2px 3px rgba(0,0,0,0.4)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  headerwrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: "100%",
  },
  logowrap: {
    "& img": {
      width: "250px",
      padding: "5px 0",
    },
  },
}));

const PublicLayouts = (props) => {
  const auth = useContext(AuthContext);
  const [layoutLoader, setLayoutLoader] = useState(false);
  const classes = useStyles();

  return (
    <>
      <CommonContext.Provider
        value={{
          loader: layoutLoader,
          setLoader: setLayoutLoader,
        }}
      >
        <div className={`DefaultContainer `}>
          {layoutLoader === true && <Loader />}
          <div className={classes.root}>
            <CssBaseline />
            {auth?.status === true && (
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                  <div className={classes.headerwrap}>
                    <Link to="/" className={classes.logowrap}>
                      <Typography
                        component="h6"
                        variant="h6"
                        className={"pr-2"}
                      >
                        <img src={csmWhiteText} alt="PROTeam Admin Logo" />
                      </Typography>
                    </Link>
                  </div>
                </Toolbar>
              </AppBar>
            )}
            <main className={classes.content}>
              <div className={classes.toolbarmirror} />
              <div className="content-wrap">{props.children}</div>
            </main>
            <Footer />
          </div>
        </div>
      </CommonContext.Provider>
    </>
  );
};

export default PublicLayouts;
