import React, { useState, useEffect } from "react";
import { firebase } from "../../services/firebase-config";

const ImageLoader = (props) => {
    const {item,height} = props;
  const [path, setPath] = useState("");
  useEffect(() => {
    (async () => {
      console.log("doing query", item);
      var ref = firebase.storage().ref().child('issues/'+item?.name);
      // Get the download URL
      console.log(ref, 'hello');
      setPath(item.url);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.url]);
  return (
    <div
      style={{
        height: height ? height : 200,
        border: "solid grey 1px",
        marginBottom: 8,
      }}
    >
      <img src={path} alt="" style={{ margin: "auto", height: height ? height - 4 : 196 }} />
    </div>
  );
};
export default ImageLoader;
