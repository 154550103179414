export const BaseUrl =
  process.env.REACT_APP_CUSTOM_ENV === "prod" ? 'https://us-central1-csmg-a0cd2.cloudfunctions.net/' : 'https://us-central1-csmg-dev-dc546.cloudfunctions.net/';

export const DOMAINURL = process.env.REACT_APP_CUSTOM_ENV === "prod" ? 'proteams.io' : 'dev.proteams.io';
export const APP_DOMAINURL = process.env.REACT_APP_CUSTOM_ENV === "prod" ? 'https://appview.proteams.io/auth/loginweb/' : 'https://appview.proteams.io/auth/loginweb/';

export const ROLES = {
  CLIENT: 'Client',
  EMPLOYEE: 'Employee',
  ADMIN: 'admin',
  SUPPORT: 'support',
};

export const EMP_ROLES = {
  CLIENT_TEAM: 'Client Team',
  ADMIN: 'Admin',
  GUEST: 'Guest',
  SUPPORT: 'Support'
};
export const COLLECTIONS = {
  USERS: 'users',
  EMPLOYEE: 'employees',
  GROUPS: 'groups',
  CLIENT: 'clientsNew',
  SHIFTS: 'shifts',
  TIMESHEETS: 'timesheets',
  STANDARDTAKS: 'standardTasks',
  SUPPLIES_CLEANINGS: 'supplies_cleanings',
  SUPPLIES_ORDER: 'supply_order',
  SUPPLIES_CATEGORY: 'supplies_category',
  SUPPLIES_ORDER_NOTES: 'supply_order_notes',
  ISSUES: 'issues',
  ADMIN_STAT_DATA_COUNT: 'stat_data',
  NOTIFICATIONS: 'notifications',
  ALERT: 'alerts',
  CHAT_CHANNELS: 'chat_channels',
  CHAT_MESSAGES: 'chat_messages',
  USER_ACTIVITY_LOG: 'user_activity_log',
};
