import React, { useState } from "react";
import styles from "./Attachments.module.scss";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
}));

const PreviewItem = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const { item } = props;
  const [open, setOpen] = useState(false);
  let prevurl = URL.createObjectURL(item);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <button type="button" onClick={handleOpen}>
          <img src={prevurl} alt="" width="40" />
        </button>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
            <img src={prevurl} alt="" />
        </div>
      </Modal>
    </>
  );
};

const AttachmentsView = (props) => {
  const { attachments } = props;

  const fileItem = (item, index) => {
    return (
      <li key={"file"+index} className={styles.attachmentItem}>
        <PreviewItem item={item} />
      </li>
    );
  };
  return (
    <ul className="attachments d-flex">
      {attachments &&
        attachments?.length > 0 &&
        attachments?.map(
          (fileitem, index) => fileItem(fileitem, index)
          // return <li className={styles.attachmentItem}>{fileitem.name}{fileitem.type}</li>;}
        )}
    </ul>
  );
};
export default AttachmentsView;
