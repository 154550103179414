/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { faExclamation, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Typography } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTablen from "../../../components/Table/ThemeTablen";
import CommonContext from "../../../hooks/commonContext";
import { getFormatedDate, getFormatedTime } from "../../../services/helpers";
import {
  closeIssueApi,
  getOneIssue,
} from "../../../services/apiservices/issues.js";
import { DetailsColumns } from "../IssuesManageTableColumns";
import TableToolbar from "../../../components/Table/TableToolbar";
import confirm from "../../../components/DialogComponent/confirm";
import EscalateForm from "./EscalateForm";
import ViewAttachments from "./ViewAttachments";

function IssueDetails(props) {
  let history = useNavigate();
  const { issueId } = useParams();
  const commonContext = useContext(CommonContext);
  const [IssueDetail, setIssueDetail] = useState([]);

  const fetchIssuesDetails = async () => {
    if (issueId && issueId !== "") {
      commonContext?.setLoader(true);
      await getOneIssue(
        issueId,
        async (res) => {
          if (res && res.status === true) {
            let details = {
              ...res.data,
              issuueId: String(res.data?.issueNumber).padStart(6, '0'),
              employeeName: res.data?.employee?.name,
              clientName: res.data?.client?.name,
              dateOfRequest: res.data?.dateOfRequest,
            };
            let responses =
              details?.responses &&
              [...Object.keys(details?.responses)].map((key) => {
                return { ...details?.responses[key], id: key };
              });
            responses = responses
              ? responses.sort((a, b) => (a.respondOn > b.respondOn ? 1 : -1))
              : [];
            setIssueDetail({ ...details, responses: responses });
            commonContext?.setLoader(false);
          } else {
            commonContext?.setLoader(false);
          }
        },
        (resError) => {
          console.log("reserror", resError);
          commonContext?.setLoader(false);
        }
      );
      commonContext?.setLoader(false);
    }
  };
  useEffect(() => {
    fetchIssuesDetails();
  }, [issueId]);

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    let boldclass = rcellItem.status === "Pending" ? "fw-bold" : "";
    switch (hcellItem.id) {
      case "dateOfRequest":
        return (
          <p className={boldclass}>
            {getFormatedDate(rcellItem.dateOfRequest)}{" "}
          </p>
        );
      case "openedOn":
        return (
          <p className={boldclass}>{getFormatedDate(rcellItem.openedOn)} </p>
        );
      case "lastupdated":
        return (
          <p className={boldclass}>{getFormatedDate(rcellItem.updatedAt)} </p>
        );
      case "lastupdatedtime":
        return (
          <p className={boldclass}>{getFormatedTime(rcellItem.updatedAt)} </p>
        );
      // lastupdated:"June 15, 2021",
      // lastupdatedtime:'10:34:56 am',
      case "priority":
        return (
          <p className={boldclass}>
            {rcellItem[hcellItem?.id]}
            {rcellItem[hcellItem?.id] === "High" && (
              <FontAwesomeIcon
                className="color-danger mh-5"
                icon={faExclamation}
              />
            )}
            {rcellItem.escalated_to_admin &&
              Boolean(rcellItem.escalated_to_admin) === true && (
                <FontAwesomeIcon className="mh-5" icon={faUsers} />
              )}
          </p>
        );
      default:
        return <p className={boldclass}>{rcellItem[hcellItem?.id]} </p>;
    }
  };
  const closeIssue = async () => {
    if (issueId && issueId !== "") {
      commonContext?.setLoader(true);
      await closeIssueApi(
        issueId,
        async (res) => {
          history('/issues/manage');
        },
        (resError) => {
          commonContext?.setLoader(false);
        }
      );
      commonContext?.setLoader(false);
    }
  };
  const OnClickCloseIssue = async () => {
    await confirm("Are you sure you want to Close this issue  ?").then(
      () => {
        closeIssue();
        console.log("close issue");
      },
      () => console.log("cancel!")
    );
  };

  return (
    <Container>
      <PageTitle
        pageTitle="Issue Management Detail"
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Issue Manage", to: "/issues/manage/" },
        ]}
        BreadCrumbCurrentPageTitle={"Edit Issue"}
      // showLastSeen={true}
      />

      <div className={`sectionBox deissue`}>
        <TableToolbar
          title=""
          leftComponent={
            <Typography variant="h6">
              {/* Issue #{issueId} - {IssueDetail?.employee?.name} */}
            </Typography>
          }
          rightComponent={
            <Button
              variant="contained"
              className="themebutton flatbutton bg-alert"
              onClick={() => OnClickCloseIssue()}
            >
              Close Issue
            </Button>
          }
        />

        <ThemeTablen
          rows={[IssueDetail]}
          headCells={DetailsColumns}
          hidePagination={true}
          renderRowCell={renderRowCell}
        // selectedRows={selectedRows}
        />
        <EscalateForm
          IssueDetail={IssueDetail}
          fetchIssuesDetails={fetchIssuesDetails}
        />
        <div className="mh-15">
          <div className="issueDetails mb-20 responc">
            <Typography variant="subtitle1"><strong>Issue Description</strong></Typography>
            <Typography variant="body1">{IssueDetail?.description}</Typography>
          </div>
          <div className="issueDetails mb-20">
            {IssueDetail?.responses &&
              IssueDetail?.responses.map((responseItem, index) => {
                return (
                  <div key={index} className="mb-15 responc">
                    <Typography variant="subtitle1">
                      <em>

                        {responseItem.escalatedTo ? (
                          <>
                            Escalated to{" "}
                            <strong>Admin</strong> By{" "}
                            <strong> {responseItem.respondBy.name}</strong>{" " + getFormatedDate(responseItem.respondOn) + " " + getFormatedTime(responseItem.respondOn)}
                          </>
                        ) : (
                          <>
                            <strong> {responseItem.respondBy.name}</strong>{" " + getFormatedDate(responseItem.respondOn) + " " + getFormatedTime(responseItem.respondOn)}
                          </>
                        )}
                      </em>
                    </Typography>
                    <Typography variant="body1">
                      {responseItem.description}
                    </Typography>
                    <ViewAttachments attachments={responseItem.attachments} />
                  </div>
                );
              })}
          </div>

        </div>
      </div>
    </Container>
  );
}

export default IssueDetails;
