/**
 * Manage All Routes from one file
 */

import LoginUser from "../app/auth/LoginUser";
import RegisterUser from "../app/auth/RegisterUser";
import ForgotPassword from "../app/auth/ForgotPassword";
import HomeContainer from "../app/home/home.container";
import ProfileContainer from "../app/Profile/Profile";
import Employee from "../app/Employee/Employee";
import ViewEmployeeApp from "../app/Employee/ViewEmployeeApp";
import CreateClient from "../app/Clients/CreateClient";
import Clients from "../app/Clients/Clients";
import CreateChannel from "../app/Messages/CreateChannel";
import ManageChannel from "../app/Messages/ManageChannel";
import LiveChat from "../app/Messages/LiveChat";

import {
  Dashboard,
  PermContactCalendar,
  Person4,
  Notifications,
  ReportProblem,
  Handyman,
  ErrorOutline,
  Description,
} from "@mui/icons-material";

import PersonIcon from "@material-ui/icons/Person";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CreateEmployee from "../app/Employee/CreateEmployee";
import ManageGroups from "../app/Employee/ManageGroups";
import Supplies from "../app/Supplies/Cleaning/Supplies";
import SuppliesManage from "../app/Supplies/Cleaning/SuppliesManage";
import SuppliesRequestDetails from "../app/Supplies/Cleaning/SuppliesRequestDetails/SuppliesRequestDetails";
import ManageSuppliesRequestDetails from "../app/Supplies/Cleaning/ManageSupplyRequestDetails/ManageSupplyRequestDetails";
import SuppliesArchiveRequestDetails from "../app/Supplies/Cleaning/SuppliesArchivesDetails";
import ArchiveSupplies from "../app/Supplies/Cleaning/SuppliesArchives";
import TimeSheetReport from "../app/CrewReport/EmployeeReports/ReportList/TimeSheet";
import ClientReport from "../app/CrewReport/ClientReports/ClientReport";
import Shiftreport from "../app/CrewReport/ClientReports/ReportList/ShiftReport";
import WorkSiteReport from "../app/CrewReport/ClientReports/ReportList/WorkSiteReport";
import TaskReport from "../app/CrewReport/ClientReports/ReportList/TaskReport";
import EmployeeReport from "../app/CrewReport/EmployeeReports/EmployeeReport";
import CheckInCheckOut from "../app/CrewReport/EmployeeReports/ReportList/CheckInCheckOut"
import ActivityReport from "../app/CrewReport/EmployeeReports/ReportList/ActivityReport";
import EmailReports from "../app/CrewReport/EmailReports";

import IssueManagement from "../app/IssueManagement/IssueManagement";

import { ROLES, EMP_ROLES } from "./config";
import IssueArchive from "../app/IssueManagement/IssueArchive";
// import IssueReview from "../app/IssueManagement/IssueReview";
import IssueDetails from "../app/IssueManagement/IssueDetails/IssueDetails";
import ResponseForm from "../app/IssueManagement/IssueDetails/ResponseForm";
import Notification from "../app/Notification/Notification";
import CreateNotification from "../app/Notification/CreateNotification";
import ClientSummaryReport from "../app/Clients/ClientSummaryReport";
//import Alerts from "../app/Alerts/AlertsPage";
import AlertsPage from "../app/Alerts/AlertsPage";
import ResetPassword from "../app/auth/ResetPassword";
/**
 * Array of menu items will show on left sidebar
 */
const sidebarMenuList = [
  // {
  //   label: "Create Client",
  //   to: "/createClient",
  //   classes: "button",
  //   icon: <AddBoxIcon />,
  //   roles: [ROLES.ADMIN],
  // },
  {
    label: "Dashboard",
    to: "/dashboard",
    icon: <Dashboard />,
    roles: [ROLES.SUPPORT.toLowerCase(), ROLES.ADMIN.toLowerCase()],
  },
  {
    label: "Profile",
    to: "/profile",
    icon: <PersonIcon />,
    roles: [ROLES.SUPPORT.toLowerCase(), ROLES.EMPLOYEE.toLowerCase(), EMP_ROLES.CLIENT_TEAM.toLowerCase(), EMP_ROLES.GUEST.toLowerCase()],
  },
  {
    label: "Clients",
    to: "/clients",
    icon: <PermContactCalendar />,
    childItems: [
      { to: "/createClient", label: "Create" },
      { to: "/manageClient", label: "Manage" },
    ],
    roles: [ROLES.ADMIN.toLowerCase()],
  },
  {
    label: ROLES.EMPLOYEE,
    to: "/employee",
    icon: <Person4 />,
    childItems: [
      { to: "/createEmp", label: "Create Employee" },
      { to: "/manageEmp", label: "Manage Employee" },
      { to: "/manageGroups", label: "Manage Groups" },
    ],
    roles: [ROLES.ADMIN.toLowerCase()],
  },
  {
    label: "Notifications",
    to: "/notifications/manage",
    icon: <Notifications />,
    roles: [ROLES.SUPPORT.toLowerCase(), ROLES.ADMIN.toLowerCase()],
    childItems: [
      {
        to: "/notifications/create",
        label: "Create",
        roles: [ROLES.SUPPORT.toLowerCase(), ROLES.ADMIN.toLowerCase()],
      },
      {
        to: "/notifications/manage",
        label: "Manage",
        roles: [ROLES.SUPPORT.toLowerCase(), ROLES.ADMIN.toLowerCase()],
      },
    ],
  },

  {
    label: "Alerts",
    to: "/alerts",
    icon: <ReportProblem />,
    roles: [ROLES.SUPPORT.toLowerCase(), ROLES.ADMIN.toLowerCase()],
    childItems: [],
  },
  {
    label: "Supplies",
    to: "/supplies",
    icon: <Handyman />,
    roles: [ROLES.SUPPORT.toLowerCase(), ROLES.ADMIN.toLowerCase()],
    childItems: [
      {
        to: "/supplies/cleaning/review",
        label: "New Requests",
        roles: [ROLES.SUPPORT.toLowerCase(), ROLES.ADMIN.toLowerCase()],
      },
      {
        to: "/supplies/cleaning/manage",
        label: "Manage Request",
        roles: [ROLES.SUPPORT.toLowerCase(), ROLES.ADMIN.toLowerCase()],
      },
      {
        to:"/supplies/cleaning/archives",
        label: "Archives / History",
        roles: [ROLES.SUPPORT.toLowerCase(), ROLES.ADMIN.toLowerCase()],
      }
    ],
  },
  {
    label: "Issue Management",
    to: "/issues/manage",
    icon: <ErrorOutline />,
    childItems: [
      { to: "/issues/manage", label: "Manage" },
      // { to: "/issues/review", label: "Review" },
      { to: "/issues/archive", label: "Archives / History" },
    ],
    roles: [ROLES.ADMIN.toLowerCase()],
  },
  {
    label: "Reports",
    to: "/crew-reports",
    icon: <Description />,
    roles: [ROLES.ADMIN],
    childItems: [
      { to: "/reports/clientreport", label: "Client Reports" },
      { to: "/reports/employeereport", label: "Employee Reports" },
      // { to: "/crew-reports/checkinout", label: "Checkin / Checkout" },
    ],
  },

  {
    label: "Message Board",
    to: "/message-board",//this is reflect to badge so if change "to" value that should be change to Badge as well
    icon: <QuestionAnswerIcon />,
    roles: [ROLES.ADMIN],
    childItems: [
      { to: "/message-board/create-channel", label: "Create Channel" },
      { to: "/message-board/manage-channel", label: "Manage Channel" },
      { to: "/message-board/live-chat", label: "Live Chat" }, //this is reflect to badge so if change "to" value that should be change to Badge as well
    ],
  },
  // { to: "/dashboard", label: "Dashboard" },
  // {
  //   to: "",
  //   label: "Settings",
  //   heading: true,
  //   childItems: [
  //     { to: "/sidechild1", label: "SideChild1" },

  //   ],
  // },
];

/**
 * Routs array for authentication pages
 */
const authRoute = [
  {
    path: "/",
    component: LoginUser,
    exact: true,
    index: 1.1,
    secure: false,
    layout: "blank",
  },
  {
    path: "/login",
    component: LoginUser,
    exact: true,
    index: 1,
    layout: "blank",
    secure: false,
  },
  {
    path: "/register",
    component: RegisterUser,
    exact: true,
    index: 2,
    layout: "blank",
    secure: false,
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
    exact: true,
    index: 3,
    layout: "blank",
    secure: false,
  },
  {
    path: "/updatepassword",
    component: ResetPassword,
    exact: true,
    index: 3,
    layout: "blank",
    secure: false,
  },
];

/***
 * routes array for secure ( if user is loggedin)
 */
const secureRoutes = [
  {
    path: "/dashboard",
    component: HomeContainer,
    exact: true,
    index: 0,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/ViewEmployeeApp/:employeeId",
    component: ViewEmployeeApp,
    exact: true,
    index: 0,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/",
    component: HomeContainer,
    exact: true,
    index: 0,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/profile",
    component: ProfileContainer,
    exact: true,
    index: 10,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.SUPPORT.toLowerCase(), EMP_ROLES.CLIENT_TEAM.toLowerCase(), EMP_ROLES.GUEST.toLowerCase()],
  },
  {
    path: "/manageEmp",
    component: Employee,
    exact: true,
    index: 11,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/createEmp/:employeeId",
    component: CreateEmployee,
    exact: true,
    index: 12,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/createEmp",
    component: CreateEmployee,
    exact: true,
    index: 12,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/manageGroups",
    component: ManageGroups,
    exact: true,
    index: 13,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/manageClient",
    component: Clients,
    exact: true,
    index: 11,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/createClient/:clientId",
    component: CreateClient,
    exact: true,
    index: 12,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/createClient",
    component: CreateClient,
    exact: true,
    index: 11,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/supplies/cleaning/request/:requestId",
    component: SuppliesRequestDetails,
    exact: true,
    index: 13.11,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/supplies/cleaning/review",
    component: Supplies,
    exact: true,
    index: 13.1,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/supplies/cleaning/manage/:requestId",
    component: ManageSuppliesRequestDetails,
    exact: true,
    index: 13.21,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/supplies/cleaning/manage",
    component: SuppliesManage,
    exact: true,
    index: 13.2,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/supplies/cleaning/archives",
    component: ArchiveSupplies,
    exact: true,
    index: 13.3,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/supplies/cleaning/archives/:requestId",
    component: SuppliesArchiveRequestDetails,
    exact: true,
    index: 13.4,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/supplies/cleaning",
    component: Supplies,
    exact: true,
    index: 13,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/issues/manage",
    component: IssueManagement,
    exact: true,
    index: 16,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/issues/manage/:issueId",
    component: IssueDetails,
    exact: true,
    index: 16.1,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  // {
  //   path: "/issues/review",
  //   component: IssueReview,
  //   exact: true,
  //   index: 16,
  //   secure: true,
  //   roles: [ROLES.ADMIN],
  // },
  {
    path: "/issues/archive",
    component: IssueArchive,
    exact: true,
    index: 16,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/issues/:issueId/response",
    component: ResponseForm,
    exact: true,
    index: 16.2,
    secure: false,
    layout: "public",
    roles: [ROLES.ADMIN, ROLES.EMPLOYEE, ROLES.CLIENT],
  },
  {
    path: "/notifications/manage",
    component: Notification,
    exact: true,
    index: 17,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/alerts",
    component: AlertsPage,
    exact: true,
    index: 18,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/notifications/create",
    component: CreateNotification,
    exact: true,
    index: 17,
    secure: true,
    roles: [ROLES.ADMIN, ROLES.SUPPORT.toLowerCase()],
  },
  {
    path: "/admin/clientSummary",
    component: ClientSummaryReport,
    exact: true,
    index: 19,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/reports/clientreport",
    component: ClientReport,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/reports/shiftreport",
    component: Shiftreport,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/reports/worksitereport",
    component: WorkSiteReport,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/reports/taskreport",
    component: TaskReport,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/reports/employeereport",
    component: EmployeeReport,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/reports/employeereports/checkincheckout",
    component: CheckInCheckOut,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/reports/employeereports/timesheetreports",
    component: TimeSheetReport,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/reports/employeereports/activityreports",
    component: ActivityReport,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/emailreports",
    component: EmailReports,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/message-board/create-channel",
    component: CreateChannel,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/message-board/create-channel/:channelId",
    component: CreateChannel,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/message-board/manage-channel",
    component: ManageChannel,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
  {
    path: "/message-board/live-chat",
    component: LiveChat,
    exact: true,
    index: 20,
    secure: true,
    roles: [ROLES.ADMIN],
  },
];
const routes = [...authRoute, ...secureRoutes];

export { sidebarMenuList, routes, secureRoutes };
