import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import UpdateIcon from '@mui/icons-material/Update';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import { Grid, Link } from "@material-ui/core";
import { getDocCount } from "../../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../../services/config";
import styles from "../../dashboard.module.scss";
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const IssuesArray = [
  {
    name: "Jack Wright",
    issue: "issue with supplies",
    time: "5 Min ago",
    status: "Urgent",
  },
  {
    name: "Jack Wright",
    issue: "issue with supplies",
    time: "5 Min ago",
    status: "Urgent",
  },
  {
    name: "Jack Wright",
    issue: "issue with supplies",
    time: "5 Min ago",
    status: "Urgent",
  },
];

export default function IssuesList() {
  const classes = useStyles();
  const [issueData, setIssueData] = useState([]);
  const [status, setStatus] = useState("High");
  const statusList = [
    { label: "Urgent", value: "High" },
    { label: "Normal", value: "Low"},
    

  ];
  const onChangeInput = (e) => {
    setStatus(e.target.value);
  };
  React.useEffect(() => {
    getDocCount(
      COLLECTIONS.ISSUES,
      (res) => {
        // console.log("reslist", res);
        setIssueData(res ? res : []);
        
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
    
  }, []);

  const chagedtime = (time)=>{
 const timeno = moment(time);
 var duration = ` ${moment(timeno).format('MMM DD, YY')}${'\n'} ${moment(timeno).format('hh:mm a')} `
 
//var formatted = duration.format("hh:mm:ss");
return duration;
  }
  //console.log(chagedtime(1629987274101))
  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={7}>
          <p className="p-0 m-0 mb-5 fonthe paddal">
            Issues
          </p>
        </Grid>
        <Grid item xs={12} sm={5}>
          <SingleSelect
            //   label="status"
            value={status}
            className={`field newonr`}
            name={"status"}
            onChange={(e) => onChangeInput(e)}
            options={statusList}
          />
        </Grid>
      </Grid>
      <List dense className='ulhideis'>
        {issueData.map((value, index) => {
          
          if( status!=="Closed" && status===value.priority){
            return (
              <ListItem  dense alignItems="flex-start" className={` paddnonoti nopadd ${styles.listItem}`} key={index} >
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp">{(value?.employee.name[0]).toUpperCase()}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={value?.employee.name ? value?.name : ""}
                  secondary={
                    <React.Fragment>
                      <Grid container className="marisu">
                        <Grid item sm={12} md={4} >
                        <Typography
                        component="span"
                        variant="body2"
                        className={`${classes.inline} ${'capit'}`}
                        color="textPrimary"
                      >
                        {value?.employee.name}
                      </Typography>
                        </Grid>
                        <Grid item sm={12} md={8} className={`text-right ${value?.priority ==='High'?'color-danger':'color-dark' }`}>
                        &#9679; {value?.priority ? value?.priority : ""}
                        </Grid>
                      </Grid>
                      <Typography
                        component="span"
                        variant="body2"
                        className={'capitis'}
                        color="textPrimary"
                      >{value?.description ? value?.description.substring(0, 30) + "..." : ""}</Typography>
                      <Grid container className="marisu">
                        
                        <Grid item sm={12} md={12} className="">
                        <Typography variant="body2" className="margne"><UpdateIcon className='greencol iconcoln svg-icon' />{chagedtime(value.openedOn)}</Typography> 
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          }
          
        })}
      </List>
      <p className="text-center">
         <Link className="link" href="/issues/manage">View all Issues</Link></p>
    </>
  );
}
