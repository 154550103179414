/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../components/AttachmentsView/Attachments.module.scss";
import ImageLoader from "../../../components/AttachmentsView/ImageLoader";
//import FileViewer from "react-file-viewer";

const ViewAttachments = (props) => {
  const [attachedFiles, setAttachedFiles] = useState([]);

  useEffect(() => {
    setAttachedFiles(props.attachments);
  }, [props.attachments]);

  const fileItem = (item, index) => {
    return (
      item !== "" && (
        <li key={"file" + index} className={styles.attachmentItem}>
          <PreviewItem item={item} />
        </li>
      )
    );
  };
  return (
    <ul className="attachments d-flex">
      {attachedFiles &&
        attachedFiles?.length > 0 &&
        attachedFiles?.map(
          (fileitem, index) => fileItem(fileitem, index)
          // return <li className={styles.attachmentItem}>{fileitem.name}{fileitem.type}</li>;}
        )}
    </ul>
  );
};
export default ViewAttachments;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
}));

const PreviewItem = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const { item } = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <button type="button" onClick={handleOpen}>
        <ImageLoader item={item} height={20} />
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <ImageLoader item={item} />
          {/* <FileViewer
            fileType={item.contentType}
            filePath={item.url}
            onError={console.log}
          /> */}
        </div>
      </Modal>
    </>
  );
};
