import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import {
  Grid,
  Typography,
  TextField,
  IconButton
} from "@material-ui/core";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import ThemeTable from "../../../components/Table/ThemeTable";
import useClientSummary from "./useClientSummary";
import styles from "../dashboard.module.scss";
function ClientSummary() {
  const {
    sort,
    filterDate,
    clientsList,
    isLoading,
    handleDateChange,
    renderRow,
    onChangeInput,
    sortBy,
    Columns,
    setShow,
    getClientsSummaryData
  } = useClientSummary(true);

  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = async () => {
    setRefreshing(true);
    setShow(false);
    await getClientsSummaryData(true);
    setRefreshing(false);
  };

  return (
    <>
      <Grid container alignItems="center" spacing={2} className="mb-10">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            <StickyNote2OutlinedIcon className="color-primary mr-10 svg-icon" />
            Client Summary
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="d-flex groupsearch float-right">
            <div className="mh-12">
              <IconButton onClick={handleRefresh} disabled={refreshing}>
                <FontAwesomeIcon icon={faSyncAlt} className={styles.Action} />
              </IconButton>
            </div>
            <div className=" mh-12">
              <TextField
                name="date"
                value={filterDate}
                fullWidth={true}
                size="small"
                variant="outlined"
                className={`field`}
                type={"date"}
                inputProps={{
                  className: "p-10",
                  min: moment().add(-30, "days").format("YYYY-MM-DD"),
                  max: moment().add(15, "days").format("YYYY-MM-DD"),
                }}
                onChange={handleDateChange}
              />
            </div>
            <div className=" mh-12">
              <SingleSelect
                value={sort}
                className={`field`}
                name={"sort"}
                onChange={(e) => onChangeInput(e)}
                options={sortBy}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div
        className={`sectionBox nospacing shadow position-relative clientWrap ${styles.summaryTableWrap}`}      >
        <ThemeTable
          rows={clientsList}
          headCells={Columns}
          hidePagination={true}
          isLoading={isLoading || refreshing}
          renderRowCell={renderRow}
        />

        <p className="text-center">

          <Link to="/admin/clientSummary" className='link'>View all Client Summary</Link>
        </p>
      </div>
    </>
  );
}

export default ClientSummary;
