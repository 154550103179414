import moment from 'moment';
/**
 *
 * @param {array} a
 * @param {array} b
 * @param {*} orderBy
 * @returns comparition of two array
 * used for table sorting dynamic
 */
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param {*} order
 * @param {*} orderBy
 * @returns sorted list of array by give order
 */
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 *
 * @param {array} array
 * @param {*} comparator
 * @returns sort given array through comparator
 */
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {

    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getFilteredBy(rows, filterBy) {
  const filterKeys = Object.keys(filterBy);

  let frows = rows.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      if (filterBy[key] !== '') {
        if (key === 'name') {
          return String(item[key])
            .toLowerCase()
            .includes(String(filterBy[key]).toLowerCase());
        } else if (
          [
            'dateOfRequest',
            'created_time',
            'updatedAt',
            'lastupdated',
            'lastupdatedtime',
            'createdOn',
          ].includes(key)
        ) {
          if (filterBy[key].start !== '' && filterBy[key].end !== '') {
            const date = moment(item[key]).format('YYYY-MM-DD');
            const start = moment(filterBy[key].start).format('YYYY-MM-DD');
            const end = moment(filterBy[key].end).format('YYYY-MM-DD');
            if (filterBy[key].start === filterBy[key].end) {
              return date > start && date < end;
            } else {
              return date >= start && date <= end;
            }
          } else {
            return true;
          }
        } else {
          return (
            String(filterBy[key]).toLowerCase() ===
            String(item[key]).toLowerCase()
          );
        }
      } else {
        return true;
      }
    });
  });

  return frows;
}

/**
 *
 * @returns Random Number
 */
export function getRandomNumber() {
  const min = 1;
  const max = 100;
  const rand = min + Math.random() * (max - min);
  return rand;
}

/**
 *
 * @param {string} str
 * @returns unique string id (prefix given str)
 */
export function getuniqueId(str) {
  var uniq = (str ? str : '') + new Date().getTime();
  return uniq;
}

/**
 *
 * @param {String} url
 * @param {String} key
 * @returns value of parameter from url
 */
export function getParamsFromUrl(url, key) {
  const queryString = url;
  const urlParams = new URLSearchParams(queryString);
  let val = urlParams.get(key);
  return val ? val : false;
}

/**
 * Generate password for new registred users
 * @returns password string
 */
export function generateP() {
  var pass = '';
  var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$';
  for (var i = 1; i <= 10; i++) {
    var char = Math.floor(Math.random() * str.length + 1);

    pass += str.charAt(char);
  }

  return pass;
}

/**
 *
 * @param {datetime} startTime
 * @param {datetime} endTime
 * @returns duration bitween two date times
 */
export function getDuration(startTime, endTime) {
  if (startTime && endTime) {
    // start time and end time
    var startTimeA = moment(startTime, 'HH:mm:ss a');
    //console.log(startTime);
    var endTimeA;
    if (startTime.slice(-2) === 'AM') {
      endTimeA = moment(endTime, 'HH:mm:ss a');
    } else {
      endTimeA = moment(endTime, 'HH:mm:ss a').add(1, 'd');
    }

    //console.log(startTime.slice(-2));

    // calculate total duration
    var duration = moment.duration(endTimeA.diff(startTimeA));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    return hours + ':' + minutes;
  }
  return '';
}

export function getDurationDiff(startTime, endTime) {
  if (startTime && endTime) {
    // start time and end time
    var startTimeA = moment(startTime, 'HH:mm:ss a');
    var endTimeA;
    if (startTime.slice(-2) === 'AM') {
      endTimeA = moment(endTime, 'HH:mm:ss a');
    } else {
      endTimeA = moment(endTime, 'HH:mm:ss a').add(1, 'd');
    }
    // calculate total duration
    var duration = moment.duration(endTimeA.diff(startTimeA));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    //var minutes = parseInt(duration.asMinutes()) % 60;
    if (hours <= 20) {
      return true;
    } else {
      return false;
    }
  }
  return '';
}

export function minTwoDigits(n) {
  if (n >= 0) return (n < 10 ? '0' : '') + n;
  else return n;
}

/**
 *
 * @param {datetime} startTime
 * @returns formated time
 */
export function getTimeFormat(startTime) {
  const today = moment.utc();
  const [hour, minute] = startTime.split(':');
  let sdate = moment(today).set('hours', hour).set('minute', minute);

  return moment(sdate).format('hh:mm A');
}
export function splitTimeValues(time) {
  if (time) {
    const [hourminute, p] = time.split(' ');
    const [hour, minute] = hourminute.split(':');
    return { hour, minute, p };
  }
  return {};
}
export function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

export function groupByKeyDate(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [moment(Number(obj[key])).format('MMMM D, YYYY')]: (
        hash[moment(Number(obj[key])).format('MMMM D, YYYY')] || []
      ).concat(obj),
    });
  }, {});
}

export function chagedtime(time) {
  const fireBaseTime = moment(
    time?.seconds * 1000 + time?.nanoseconds / 1000000
  );
  //console.log(fireBaseTime);
  const timeno = moment(fireBaseTime);
  const nowtime = moment.tz(timeno, 'America/New_York').format();
  // const nowtimen = moment(nowtime);
  //console.log(nowtime);
  var duration = ` ${moment(nowtime).format('MMM DD, YY')} ${'\n'} ${moment(
    nowtime
  ).format('hh:mm:ss')}`;

  //var formatted = duration.format("hh:mm:ss");
  return duration;
}
export function getFormatedDate(timestamp) {
  return timestamp
    ? moment(Number(timestamp)).format('MMMM D, YYYY')
    : timestamp;
}
export function getFormatedTime(timestamp) {
  return timestamp ? moment(Number(timestamp)).format('HH:mm:ss A') : timestamp;
}
/**
 *
 * @param {array} myArray
 * @returns unique array
 */
export function getUniqueArray(myArray) {
  return myArray.filter((v, i, a) => a.indexOf(v) === i);
}


export function formatDate(timestamp) {
  const date = timestamp ? new Date(timestamp.seconds * 1000) : null;
  return date ? moment(date).format("MMM DD, YYYY") : "---";
};

export function convertFirestoreTimestampToDate(timestamp) {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
};