/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";

import confirm from "../../../components/DialogComponent/confirm";
//import SingleSelect from "../../../components/SelectField/SingleSelect";
import { addIssueResponse } from "../../../services/apiservices/issues";
import FileUploadComponent from "../../../components/Fields/FileUploadComponent";
import AttachmentsView from "../../../components/AttachmentsView/AttachmentsView";
import AuthContext from "../../../hooks/authContext";
import CommonContext from "../../../hooks/commonContext";

function EscalateForm(props) {
  const commonContext = useContext(CommonContext);
  const {userProfile} =  useContext(AuthContext);
  const history = useNavigate();
  const [escalated, setescalated] = useState("");
  const [formData, setFormData] = useState({});
  const [errors, setFormError] = useState({});
  const [attachments, setAttachments] = useState([]);
  const { IssueDetail } = props;
  const oncleardata = () => {
    setFormData({});
    setFormError({});
    setescalated("");
    setAttachments([]);
  };
  const onCancel = async () => {
    await confirm("Are you sure you want to cancel this message ?").then(
      () => {
        oncleardata();
        history("/issues/manage");
      },
      () => {
        console.log("calcel!");
      }
    );
  };

  const checkValid = () => {
    let errormessages = errors;
    let isValid = true;
    // if (
    //   typeof formData?.escalatedTo === "undefined" ||
    //   formData?.escalatedTo === ""
    // ) {
    //   isValid = false;
    //   errormessages = {
    //     ...errormessages,
    //     escalatedTo: "Field is required",
    //   };
    // } else {
    //   errormessages = { ...errormessages, escalatedTo: "" };
    // }
    if (
      typeof formData?.description === "undefined" ||
      formData?.description === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        description: "Field is required",
      };
    } else {
      errormessages = { ...errormessages, description: "" };
    }

    setFormError(errormessages);
    return isValid;
  };

  const onResponse = async () => {
    if (true === checkValid()) {
      await confirm("Are you sure you want to response this Issue ?").then(
        async () => {
          commonContext?.setLoader(true);
          if (escalated === "Yes") {
            formData.escalated = true;
          }
          if(attachments && attachments.length > 0){
            formData.attachments = attachments;
          }
          let respondBy={id: userProfile.id, name: userProfile.firstName+" "+userProfile.lastName}
          await addIssueResponse(
            IssueDetail.id,
            formData,
            respondBy,
            (res) => {
              oncleardata();
              props.fetchIssuesDetails();
              commonContext?.setLoader(false);
            },
            (error) => {
              console.log("error", error);
              commonContext?.setLoader(false);
            }
          );
        },
        () => {
          console.log("cancel!");
        }
      );
    }
  };

  const onChangeInput = (e) => {
    const {
      target: { name, value },
    } = e;
    
    setFormData({ ...formData, [name]: value });
    setFormError({ ...errors, [name]: "" });
  };

  const onChangeInputescalated = (e) => {
    const {
      target: { value },
    } = e;
   setescalated(value);
   if(value==="Yes"){
    setFormData({ ...formData, escalatedTo: "Escalate" });
    //setFormError({ ...errors, ["escalatedTo"]: "" });
   }

  };
  const onFileUpload = (file) => {
    setAttachments([...attachments,file]);
  };

  return (
    <div className="issueDetails mb-20 formtxtbtm">
      <h3 className="formtxt">
Description
</h3>
This is some copy that will used to details the issue.should not be overlooked or misread. Insted . its should be a key focal point of this page.
      
      {/* {escalated === "Yes" && (
        <>
          <SingleSelect
            label="Select Escalated To "
            value={formData?.escalatedTo ? formData.escalatedTo : ""}
            className={`field`}
            name={"escalatedTo"}
            onChange={(e) => onChangeInput(e)}
            options={[
              { label: "Escalate", value: "Escalate" },
              
            ]}
          />
          {typeof errors?.escalatedTo !== "undefined" &&
            errors?.escalatedTo !== "" && (
              <span className="small error color-danger">
                {errors?.escalatedTo}
              </span>
            )}
        </>
      )} */}
      <FormControl fullWidth className="mv-15">
        <TextField
          id="outlined-issue-detailsinput"
          label="Message"
          multiline
          rows={4}
          name="description"
          value={formData?.description ? formData.description : ""}
          variant="outlined"
          onChange={onChangeInput}
        />
        {typeof errors?.description !== "undefined" &&
          errors?.description !== "" && (
            <span className="small error color-danger">
              {errors?.description}
            </span>
          )}
      </FormControl>
      <FormControl
        fullWidth
        component="fieldset"
        className="d-flex flex-center flex-direction-row"
      >
        <FormLabel className="mr-20 strong">Escalate</FormLabel>
        <RadioGroup
          aria-label="Escalated"
          name="escalated"
          row={true}
          value={escalated ? escalated : ""}
          onChange={onChangeInputescalated}
        >
          <FormControlLabel
            value={"Yes"}
            control={<Radio color="primary" />}
            label={"Yes"}
          />
          <FormControlLabel
            value={"No"}
            control={<Radio color="primary" />}
            label={"No"}
          />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth className="mv-15">
        <FormLabel className="mr-20 strong">Attachments</FormLabel>

        <AttachmentsView attachments={attachments} />
        <FileUploadComponent onuploadfile={onFileUpload} />
      </FormControl>
      <div className="buttonswrap">
        <Button
          variant="contained"
          className="flatbutton themebutton mr-15"
          color="primary"
          onClick={onResponse}
        >
          Response
        </Button>
        <Button
          variant="contained"
          className="flatbutton themebutton mr-15"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default EscalateForm;
