/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../../../hooks/commonContext";
import AuthContext from "../../../../hooks/authContext";
import { Container, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../../components/Table/ThemeTable";
import { Columns } from "./manageSuppliesRequestTableColumns";
import {
  getCategories, addNotes, fetchNotes, updateSupplyItem, updateStatus,
  getSupplyOrderListBasedOnId,
  updateArchive
} from "../../../../services/apiservices/supplies";
import SupplyRequestDetails from "./SupplyRequestDetails";
import moment from "moment";
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getClientsListbasedonIds } from "../../../../services/apiservices/client";
import { getAllEmployee, getEmployeesListBasedOnId } from "../../../../services/apiservices/employee";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { convertFirestoreTimestampToDate, formatDate } from "../../../../services/helpers";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const NotesSection = ({ notes, onAddNote, employee, createdBy, orderId, setNotes }) => {
  const [newNote, setNewNote] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleAddNote = async () => {
    if (newNote.trim()) {
      const note = {
        createBy: createdBy,
        note: newNote.trim(),
        orderId: orderId,
      };
      try {
        const addedNote = await addNotes(note);
        onAddNote && onAddNote(addedNote);
        fetchNotes(orderId, (fetchedNotes) => {
          setNotes(fetchedNotes);
        });
      } catch (error) {
        console.error('Error adding note: ', error);
      }

      setNewNote('');
    }
  };

  const handleCancel = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setDialogOpen(false);
    navigate(-1);
    setNewNote('');
  };

  const createBy = (id, employees) => {
    const employee = employees.find(item => item.value === id);
    const data = employee ? employee.label : "";
    return data;
  };

  const sortedNotes = [...notes].sort((a, b) => {
    const dateA = convertFirestoreTimestampToDate(a.createdAt);
    const dateB = convertFirestoreTimestampToDate(b.createdAt);
    return dateB - dateA;
  });

  return (
    <>
      <div className="note-input mb-30">
        <TextField
          label="Add Notes"
          multiline
          rows={3}
          variant="outlined"
          className="mt-15"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          fullWidth
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddNote}
          className="mt-15"
        >
          Submit
        </Button>
        <Button
          variant="contained"
          onClick={handleCancel}
          className="mt-24 m-10"
        >
          Cancel
        </Button>
      </div>

      <strong className="mt-20"> Notes </strong>
      <hr className="note-divider mb-25" />
      <div className="notes-section">
        {sortedNotes[0]?.message ? (
          <p>No Notes Available</p>
        ) : (
          sortedNotes.map((note, index) => (
            <div key={index} className="note">
              <p>{note?.note}</p>
              <p>
                <strong>Date:</strong> {formatDate(note?.createdAt)}{' '}
                <strong className="ml-10">Created By:</strong> {createBy(note?.createBy, employee)}
              </p>
              {index < sortedNotes.length - 1 && <hr className="note-divider" />}
            </div>
          ))
        )}
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};



const SupplyStatus = ({ rcellItem, rowIndex, onUpdateStatus }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectStatus = (status) => {
    onUpdateStatus(status);
    handleClose();
  };

  const availableStatuses = ['Ordered', 'Shipped', 'Delivered', 'Back Ordered', 'Cancel Item'].filter(status => status !== rcellItem.status);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{rcellItem?.status}</span>
      <IconButton
        size="small"
        onClick={handleClick}
        color="primary"
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {availableStatuses.map(status => (
          <MenuItem key={status} onClick={() => handleSelectStatus(status)}>{status}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};


const ManageSuppliesRequestDetails = (props) => {
  const commonContext = useContext(CommonContext);
  const { userProfile } = useContext(AuthContext);
  const { requestId } = useParams();
  const [requestData, setRequestData] = React.useState({});
  const [allSupplies, setAllSupplies] = React.useState([]);
  const [notes, setNotes] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [openDatePickerId, setOpenDatePickerId] = useState(null);
  const [categories, setCategories] = useState({});
  const [empName, setEmployeeName] = useState();
  const navigate = useNavigate();

  const getRequestData = (requestId) => {
    if (typeof requestId !== "undefined" && requestId !== "") {
      commonContext?.setLoader(true);

      getSupplyOrderListBasedOnId(
        requestId,
        async (res) => {
          if (!res) {
            setRequestData({});
            setAllSupplies([]);
            commonContext?.setLoader(false);
            return;
          }

          await fetchAndStoreCategories(res?.supplyItems);

          const deliveryStatus = await getDeliveryStatus(res?.supplyItems);

          let clientIds = [res.clientId];
          let empIds = [res.createdBy];

          let clintsData = [];
          await getClientsListbasedonIds(clientIds, (result) => {
            const clients = result.map((item) => {
              return { value: item?.id, label: item?.clientName };
            });
            clintsData = clients;
          });

          let employeeData = [];
          await getEmployeesListBasedOnId(empIds, (result) => {
            const employees = result.map((item) => {
              return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}` };
            });
            employeeData = employees;
          });

          await getAllEmployee(
            (result) => {
              const employees = result.data.map((item) => {
                return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}` };
              });
              setEmployeeName(employees);
            });

          const client = clintsData.find(client => client?.value === res?.clientId);
          const clientName = client ? client.label : 'Unknown Client';

          const employee = employeeData.find(emp => emp?.value === res?.createdBy);
          const employeeName = employee ? employee.label : 'Unknown Employee';

          let priorityLabel;
          switch (res.priority) {
            case "1":
              priorityLabel = "High";
              break;
            case "2":
              priorityLabel = "Medium";
              break;
            case "3":
              priorityLabel = "Low";
              break;
            default:
              priorityLabel = "Unknown";
          }

          const finaleData = {
            ...res,
            priority: priorityLabel,
            orderNumber: String(res.orderNumber).padStart(6, '0'),
            clientName: clientName,
            employeeName: employeeName,
            deliveryStatus: deliveryStatus
          };

          setRequestData(finaleData);
          setAllSupplies(finaleData?.supplyItems);

          commonContext?.setLoader(false);
        },
        (resError) => {
          commonContext?.setLoader(false);
        }
      );
    } else {
      setRequestData({});
      setAllSupplies([]);
      commonContext?.setLoader(false);
    }
  };

  useEffect(() => {
    getRequestData(requestId);
  }, [requestId]);

  useEffect(() => {
    const loadNotes = async () => {
      if (!requestData?.id) {
        setNotes([{ message: 'No ID provided' }]);
        return;
      }

      fetchNotes(
        requestData.id,
        (fetchedNotes) => {
          setNotes(fetchedNotes);
        },
        (error) => {
          console.error('Error fetching notes: ', error);
          setNotes([{ message: 'Error fetching notes' }]);
        }
      );
    };

    loadNotes();
  }, [requestData?.id])

  const handleNote = (newNote) => {
    setNotes([...notes, newNote]);
  };

  const fetchAndStoreCategories = async (items) => {
    for (const item of items) {
      if (item?.parentCategoryId) {
        await getCategories(item?.parentCategoryId, (categoriesData) => {
          const category = categoriesData.find((cate) => cate?.id === item?.parentCategoryId);
          const title = category?.title;
          if (title) {
            setCategories((prevCategories) => ({
              ...prevCategories,
              [item.parentCategoryId]: title,
            }));
          }
        });
      }
    }
  };

  const getDeliveryStatus = (supplyItems) => {
    if (!supplyItems || !Array.isArray(supplyItems)) {
      return 'Invalid Supplies';
    }

    const statuses = supplyItems.map(item => item.status);

    const hasDelivered = statuses.includes('Delivered');
    const hasShipped = statuses.includes('Shipped');
    const hasOrdered = statuses.includes('Ordered');
    const hasBackOrdered = statuses.includes('Back Ordered');
    const hasCancelItem = statuses.includes('Cancel Item');

    if (statuses.every(status => status === 'Delivered') || (hasDelivered && hasCancelItem && !hasOrdered && !hasBackOrdered && !hasShipped)) {
      return 'Full Delivery';
    }
    if (statuses.every(status => status === 'Cancel Item')) {
      return 'Full Delivery';
    }
    if (hasDelivered && (hasShipped || hasOrdered || hasBackOrdered || hasCancelItem)) {
      return 'Partial Delivery';
    }
    if (statuses.every(status => status === 'Shipped') || (hasShipped && hasCancelItem && !hasDelivered && !hasOrdered && !hasBackOrdered)) {
      return 'Full Shipment';
    }
    if (hasShipped && (hasOrdered || hasBackOrdered) && !hasDelivered) {
      return 'Partial Shipment';
    }
    if (hasOrdered && hasCancelItem) {
      return 'In Process';
    }
    if (statuses.every(status => status === 'Ordered')) {
      return 'In Process';
    }
    if (statuses.every(status => status === 'Back Ordered')) {
      return 'Back Ordered';
    }
    if (hasOrdered && hasBackOrdered) {
      return 'In Process';
    }
    return 'In Process';
  };

  const handleUpdateStatus = async (requestData, rcellItem, newStatus) => {
    try {
      await updateStatus(
        requestData.id,
        rcellItem.id,
        newStatus,
        (data) => {
          if (data) {
            getRequestData(requestId);
          }
        },
        (error) => {
          console.error('Failed to update status:', error);
        }
      );
    } catch (error) {
      console.error('Error in handleUpdateStatus:', error);
    }
  };

  const handleDateIconClick = (id) => {
    if (openDatePickerId === id) {
      setShowDatePicker(false);
      setOpenDatePickerId(null);
    } else {
      setOpenDatePickerId(id);
      setShowDatePicker(true);
    }
  };

  const handleDateChangeConfirmed = (id, date) => {
    const utcDate = dayjs(date).toISOString();
    updateDeliveryDate(requestData.id, id, utcDate);
    setShowDatePicker(false);
  };

  const handleDateChangeCancelled = () => {
    setShowDatePicker(false);
  };

  const handleArchive = async () => {
    const formData = {
      archive: true
    }
    await updateArchive(requestData.id, formData);
    navigate(-1);
  }

  const isUndelivered = requestData.supplyItems && requestData.supplyItems.every(item => item.status === 'Delivered' || item.status === 'Cancel Item');

  const updateDeliveryDate = async (requestId, itemId, date) => {
    try {
      await updateSupplyItem(
        requestId,
        itemId,
        date,
        (data) => {
          if (data) {
            getRequestData(requestId);
          }
        },
        (error) => {
          console.error("Failed to update status:", error);
        }
      );
    } catch (error) {
      console.error("Error in handleUpdateStatus:", error);
    }
  };


  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "categories":
        if (rowindex !== 0 && categories[rcellItem?.parentCategoryId] !== categories[requestData.supplyItems[rowindex - 1]?.parentCategoryId]) {
          return <span>{categories[rcellItem?.parentCategoryId]}</span>
        }
        else if (rowindex === 0) {
          return <span>{categories[rcellItem?.parentCategoryId]}</span>
        } else {
          return "";
        }
        break;
      case "supplies":
        return `${rcellItem?.title} (${rcellItem?.qty})`;
        break;
      case "deliveryDate":
        // if (requestData.status === "Approved") {
        const data = convertFirestoreTimestampToDate(rcellItem?.deliveryDate);
        const dates = dayjs(data).format('YYYY-MM-DD').isValid;
        return (
          <>
            <span>
              {rcellItem?.deliveryDate ? formatDate(rcellItem?.deliveryDate) : "---"}
            </span>

            <IconButton className="p-5" >
              <DateRangeIcon className="color-primary" style={{ padding: 0 }} fontSize="small" onClick={() => handleDateIconClick(rcellItem?.id)} />
            </IconButton>
            {showDatePicker && openDatePickerId === rcellItem?.id && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DesktopDatePicker']}>
                  <DesktopDatePicker
                    value={dayjs(dates).local()}
                    onAccept={(date) => handleDateChangeConfirmed(rcellItem?.id, date)}
                    onClose={() => {
                      handleDateChangeCancelled();
                      setOpenDatePickerId(null);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    format="YYYY-MM-DD"
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}
          </>
        );
        // } else {
        //   return "---";
        // }
        break;
      case "status":
        return (
          <SupplyStatus
            rcellItem={rcellItem}
            rowIndex={rowindex}
            onUpdateStatus={(status) => handleUpdateStatus(requestData, rcellItem, status)}
          />
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Supplies"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Supplies Manage Request", to: "/supplies/cleaning/manage" },
          ]}
          BreadCrumbCurrentPageTitle={"View Supplies Request"}
        />

        <div className={`sectionBox`}>

          <div className="d-flex flex-justify-end">

            <Button
              color={isUndelivered ? "primary" : ""}
              className="mr-20"
              variant="contained"
              onClick={isUndelivered ? handleArchive : null}
              disabled={!isUndelivered}
            >
              Archive
            </Button>
          </div>


          <div className="p-18">

            <SupplyRequestDetails requestData={requestData} />

            <ThemeTable
              rows={allSupplies}
              headCells={Columns}
              hidePagination={true}
              renderRowCell={renderRowCell}
              supplies={true}
              requestData={requestData}
              categories={categories}
            />

            <NotesSection
              notes={notes}
              onAddNote={handleNote}
              employee={empName}
              createdBy={userProfile?.id}
              orderId={requestData?.id}
              setNotes={setNotes}
            />

          </div>
        </div>
      </Container>
    </>
  );
};

export default ManageSuppliesRequestDetails;
