import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  TextField,
  IconButton
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import SingleSelect from "../../components/SelectField/SingleSelect";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import TableToolbar from "../../components/Table/TableToolbar";

import useClientSummary from "../home/components/useClientSummary";
import styles from "../home/dashboard.module.scss";

function ClientSummary(props) {
  const {
    sort,
    filterDate,
    clientsList,
    isLoading,
    handleDateChange,
    renderRow,
    onChangeInput,
    sortBy,
    Columns,
    getClientsSummaryData,
    setShow
  } = useClientSummary(false);

  const [refreshing, setRefreshing] = useState(false);


  const handleRefresh = async () => {
    setRefreshing(true);
    setShow(false);
    await getClientsSummaryData(); // Wait for data to be fetched
    setRefreshing(false);
  };

  const tableRightComponent = () => {
    return (
      <div className="d-flex groupsearch">
        <IconButton onClick={handleRefresh} disabled={refreshing}>
          <FontAwesomeIcon icon={faSyncAlt} className={styles.Action} />
        </IconButton>
        <div className=" mh-3">
          <TextField
            name="date"
            value={filterDate}
            fullWidth={true}
            size="small"
            variant="outlined"
            className={`field`}
            type={"date"}
            inputProps={{
              className: "p-10",
              min: moment().add(-30, "days").format("YYYY-MM-DD"),
              max: moment().add(15, "days").format("YYYY-MM-DD"),
            }}
            onChange={handleDateChange}
          />
        </div>
        <div className=" mh-3">
          <SingleSelect
            value={sort}
            className={`field`}
            name={"sort"}
            onChange={(e) => onChangeInput(e)}
            options={sortBy}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Client Summary Report"
          showBreadcrumb={[{ label: "Home", to: "/" }]}
          BreadCrumbCurrentPageTitle={"Client Summary Report"}
          showLastSeen={true}
        />

        <div className={`sectionBox clientWrap ${styles.summaryTableWrap}`}>
          <TableToolbar
            title="Client Summary Report"
            rightComponent={tableRightComponent()}
          />
          <ThemeTable
            rows={clientsList}
            headCells={Columns}
            hidePagination={true}
            isLoading={isLoading || refreshing}
            renderRowCell={renderRow}

          />
        </div>
      </Container>
    </>
  );
}

export default ClientSummary;
