/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Grid,
  Container,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import CommonContext from "../../hooks/commonContext";
import SingleSelect from "../../components/SelectField/SingleSelect";
import MultiSelect from "../../components/SelectField/MultiSelect";
import { getGroupsList } from "../../services/apiservices/groups";
import { getClientsList } from "../../services/apiservices/client";
import {
  getEmployeeByFilter,
  getEmployeesList,
} from "../../services/apiservices/employee";
import confirm from "../../components/DialogComponent/confirm";
import { useNavigate } from "react-router";
import { saveNotification } from "../../services/apiservices/notifications";

function CreateNotification(props) {
  const commonContext = useContext(CommonContext);
  const history = useNavigate();
  const empl = useRef();
  const cliens = useRef();
  const groupsl = useRef();
  const [formerror, setFormError] = useState({});
  const [formdata, setFormData] = useState({});
  const [audienceList, setAudienceList] = useState([]);
  const [getempby, setGetEmpby] = useState(false);
  const [getcliby, setGetCliby] = useState(true);

  const [clientList, setClientList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [messageTypeList, setMessageTypeList] = useState([]);
  const setIsLoading = (status) => {
    commonContext.setLoader(status);
  };
  const clearformdata = () => {
    setFormData({});
    setFormError({});
    setGetEmpby("");
    setGetCliby("");
    setAudienceList([]);
  };
  const fetchData = async () => {
    setIsLoading(true);
    getGroupsList(
      {},
      (res) => {
        if (res) {
          setGroupList(res);
          setIsLoading(false);
        }
      },
      (resError) => {
        setIsLoading(false);
      }
    );
    await getClientsList(
      {},
      (res) => {
        if (res) {
          setClientList(res);
        } else {
          setClientList([]);
        }
      },
      (resError) => {
        setClientList([]);
      }
    );
    await getEmployeesList(
      {},
      (res) => {
        if (res) {
          let allEmp = res?.map((eitem) => {
            return {
              ...eitem,
              userName: `${eitem?.firstName} ${eitem?.lastName}`,
            };
          });
          setEmployeeList(allEmp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    setIsLoading(true);
    clearformdata();
    setAudienceList([
      // { name: "abc", id: "id" },
      // { name: "back", id: "id" },
      // { name: "jack", id: "id" },
    ]);
    // setClientList([{ label: "clientone", value: "clientone" }]);
    fetchData();
    // setGroupList([
    //   { label: "Residential", value: "Residential " },
    //   { label: "Commercial", value: "Commercial " },
    //   { label: "Auditor", value: "Auditor " },
    //   { label: "Lead", value: "Lead " },
    // ]);
    // setEmployeeList([{ label: "empone", value: "empone " }]);
    setMessageTypeList([
      { label: "Push Notification", value: "PushNotification" },
      { label: "Sms", value: "Push" },
      { label: "Email", value: "Email" },
      { label: "All", value: "Both" },
    ]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getaudience();
  }, [formdata?.selectedGroup]);

  useEffect(() => {
    getaudienceCli();
  }, [formdata?.selectedClient]);

  const handlefieldChange = (name, value) => {
    setFormData({
      message: formdata?.message,
      selectedMessageType: formdata?.selectedMessageType,
      [name]: value,
    });
    setFormError({ ...formerror, [name]: "" });
    //console.log("testdr",formdata);
    if (name === "selectedClient") {
      // empl.clearValue();
      // groupsl.clearValue();
      setGetEmpby("client");
      setGetCliby("clientt");
    }
    if (name === "selectedEmployees" && value) {
      // cliens.clearValue();
      // groupsl.clearValue();
      setGetEmpby("employee");
      setAudienceList([
        ...value?.map((aitem) => {
          return {
            name: aitem.firstName,
            id: aitem.id,
            email: aitem.email,
            phone: aitem.phoneNumber,
          };
        }),
      ]);
    }
  };

  const handlefieldChangenew = (name, value) => {
    setFormData({ ...formdata, [name]: value });
    setFormError({ ...formerror, [name]: "" });
    //console.log("testdr",formdata);
    if (name === "selectedClient") {
      // empl.clearValue();
      // groupsl.clearValue();
      setGetEmpby("client");
      setGetCliby("clientt");
    }
    if (name === "selectedEmployees" && value) {
      // cliens.clearValue();
      // groupsl.clearValue();
      setGetEmpby("employee");
      setAudienceList([
        ...value?.map((aitem) => {
          return {
            name: aitem.firstName,
            id: aitem.id,
            email: aitem.email,
            phone: aitem.phoneNumber,
          };
        }),
      ]);
    }
  };

  const handlefieldChangenewm = (name, value) => {
    setFormData({ ...formdata, [name]: value });
    setFormError({ ...formerror, [name]: "" });
    //console.log("testdr",formdata);
    if (name === "selectedClient") {
      // empl.clearValue();
      // groupsl.clearValue();
      setGetEmpby("client");
      setGetCliby("clientt");
    }
    if (name === "selectedEmployees" && value) {
      // cliens.clearValue();
      // groupsl.clearValue();
      setGetEmpby("employee");
      setAudienceList([
        ...value?.map((aitem) => {
          return {
            name: aitem.firstName,
            id: aitem.id,
            email: aitem.email,
            phone: aitem.phoneNumber,
          };
        }),
      ]);
    }
  };

  const getaudienceCli = () => {
    const { selectedClient, selectedGroup } = formdata;
    if (typeof selectedClient !== "undefined") {
      getEmployeeByFilter(
        {
          clients: selectedClient,
        },
        (res) => {
          // console.log("res1", res);
          let list;

          list = res?.map((aitem) => {
            let netlt = {};
            let list2 = aitem?.clients;

            if (selectedClient === "All") {
              return {
                name: aitem.firstName,
                id: aitem.id,
                email: aitem.email,
                phone: aitem.phoneNumber,
              };
            }

            const clarr = list2.map((clien) => {
              return clien.clientid;
            });

            //console.log("listcl",clarr);
            if (selectedClient) {
              if (clarr.includes(selectedClient?.id)) {
                netlt = {
                  name: aitem.firstName,
                  id: aitem.id,
                  email: aitem.email,
                  phone: aitem.phoneNumber,
                };
              }

              return netlt;
            }
          });

          var newlist = list.filter((value) => Object.keys(value).length !== 0);
          // console.log(list);
          // console.log(newlist);

          setAudienceList(newlist);
        },
        (error) => {
          console.log("error res", error);
        }
      );
    }
  };

  const getaudience = () => {
    const { selectedGroup } = formdata;
    if (typeof selectedGroup !== "undefined") {
      getEmployeeByFilter(
        {
          clients: "All",
          groups: selectedGroup,
        },
        (res) => {
          // console.log("res1", res);
          let list;

          list = res?.map((aitem) => {
            let netlt = {};
            let list2 = aitem?.group;

            if (!selectedGroup || selectedGroup === "All") {
              return {
                name: aitem.firstName,
                id: aitem.id,
                email: aitem.email,
                phone: aitem.phoneNumber,
              };
            }

            if (selectedGroup) {
              if (list2.includes(selectedGroup?.groupId)) {
                netlt = {
                  name: aitem.firstName,
                  id: aitem.id,
                  email: aitem.email,
                  phone: aitem.phoneNumber,
                };
              }

              return netlt;
            }
          });

          var newlist = list.filter((value) => Object.keys(value).length !== 0);
          // console.log(list);
          // console.log(newlist);

          setAudienceList(newlist);
        },
        (error) => {
          console.log("error res", error);
        }
      );
    }
  };

  const checkValid = () => {
    let errormessages = formerror;
    let isValid = true;
    if (typeof formdata?.message === "undefined" || formdata?.message === "") {
      isValid = false;
      errormessages = {
        ...errormessages,
        message: "Field is required",
      };
    } else {
      errormessages = { ...errormessages, message: "" };
    }
    if (audienceList.length <= 0) {
      isValid = false;
      errormessages = {
        ...errormessages,
        selectedGroup: "Please Add employees By Name/By Client/By Group First!",
      };
    } else {
      errormessages = { ...errormessages, selectedGroup: "" };
    }

    // if (
    //   typeof formdata?.selectedClient === "undefined" ||
    //   formdata?.selectedClient.length <= 0
    // ) {
    //   isValid = false;
    //   errormessages = {
    //     ...errormessages,
    //     selectedClient: "Field is required",
    //   };
    // } else {
    //   errormessages = { ...errormessages, selectedClient: "" };
    // }
    // if (typeof formdata?.selectedGroup === "undefined") {
    //   isValid = false;
    //   errormessages = {
    //     ...errormessages,
    //     selectedGroup: "Field is required",
    //   };
    // } else {
    //   errormessages = { ...errormessages, selectedGroup: "" };
    // }
    if (
      typeof formdata?.selectedMessageType === "undefined" ||
      formdata?.selectedMessageType === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        selectedMessageType: "Field is required",
      };
    } else {
      errormessages = { ...errormessages, selectedMessageType: "" };
    }

    setFormError(errormessages);
    return isValid;
  };

  const onSaveNotification = () => {
    if (true === checkValid()) {
      setIsLoading(true);
      let formDataSave = {
        Message: formdata?.message,
        Client: formdata?.selectedClient
          ? {
              id:
                formdata?.selectedClient !== "All"
                  ? formdata?.selectedClient.id
                  : "All",
              name:
                formdata?.selectedClient !== "All"
                  ? formdata?.selectedClient.clientName
                  : "All",
            }
          : {},
        Group: formdata?.selectedGroup
          ? {
              id:
                formdata?.selectedGroup !== "All"
                  ? formdata?.selectedGroup.groupId
                  : "All",
              title:
                formdata?.selectedGroup !== "All"
                  ? formdata?.selectedGroup.title
                  : "All",
            }
          : {},
        Type: formdata?.selectedMessageType,
        read: false,
        Employee: audienceList
          ? audienceList.map((empitem) => {
              return {
                id: empitem.id,
                name: `${empitem.name}`,
                email: empitem.email,
                phone: empitem.phone,
              };
            })
          : [],
        MessageRecipents: audienceList
          ? audienceList.map((aitem) => aitem.id)
          : [],
      };
      saveNotification(
        formDataSave,
        (res) => {
          if (res.status === true) {
            // console.log("data saved", res.data);
            setIsLoading(false);
            history("/notifications/manage");
          }
        },
        (errorRes) => {
          setIsLoading(false);
          console.log("error while save notification", errorRes);
        }
      );
    }
  };

  let allaudianceListName = audienceList?.map((item) => item.name);
  let allclietnoptions = [
    { label: "All", value: "All" },
    ...clientList?.map((item) => {
      return { label: item.clientName, value: item };
    }),
  ];
  let allgroupoptions = [
    { label: "All", value: "All" },
    ...groupList?.map((item) => {
      return { label: item.title, value: item };
    }),
  ];
  let allempoptions = employeeList?.map((item) => {
    return { label: item.firstName + item.lastName, value: item };
  });

  return (
    <Container>
      <PageTitle
        pageTitle="Create Notification"
        showBreadcrumb={[{ label: "Home", to: "/" }]}
        showLastSeen={true}
      />

      <div className={`sectionBox`}>
        <Grid container spacing={2} className={"mt-20 pb-15"}>
          <Grid item sm={12} md={3}>
            <h3 className="sectiontitle ntop">Create Notification</h3>
            <div className="sectiontitlenew">Step 1 - Find employees</div>
            <div className="mb-20">
              <MultiSelect
                label="By Name"
                value={
                  formdata?.selectedEmployees
                    ? [...formdata?.selectedEmployees]
                    : []
                }
                className={`field`}
                name={"selectedEmployees"}
                onChange={(val) => handlefieldChange("selectedEmployees", val)}
                selectAllOption={true}
                options={[...allempoptions]}
                //disabled={getempby === "client" ? true : false}
              />
              {typeof formerror?.selectedEmployees !== "undefined" &&
                formerror?.selectedEmployees !== "" && (
                  <span className="small error color-danger">
                    {formerror?.selectedEmployees}
                  </span>
                )}
            </div>
            <div className="sectiontitlenew center">or</div>
            <div className="mb-20">
              <SingleSelect
                label="By Client"
                value={formdata?.selectedClient ? formdata?.selectedClient : ""}
                className={`field`}
                name={"selectedClient"}
                onChange={(e) =>
                  handlefieldChange("selectedClient", e.target.value)
                }
                options={[...allclietnoptions]}
                //disabled={getempby === "employee" ? true : false}
              />
              {/* <MultiSelect
                label="Select Client"
                value={
                  formdata?.selectedClient ? [...formdata?.selectedClient] : []
                }
                className={`field`}
                name={"selectedClient"}
                onChange={(val) => handlefieldChange("selectedClient", val)}
                selectAllOption={true}
                options={[...allclietnoptions]}
              /> */}
              {typeof formerror?.selectedClient !== "undefined" &&
                formerror?.selectedClient !== "" && (
                  <span className="small error color-danger">
                    {formerror?.selectedClient}
                  </span>
                )}
            </div>
            <div className="sectiontitlenew center">or</div>
            <div className="mb-20">
              <SingleSelect
                label="By Client Team"
                value={formdata?.selectedGroup ? formdata?.selectedGroup : ""}
                className={`field`}
                name={"selectedGroup"}
                onChange={(e) =>
                  handlefieldChange("selectedGroup", e.target.value)
                }
                options={[...allgroupoptions]}
                //disabled={getcliby === "clientt" ? false : true}
              />
              {typeof formerror?.selectedGroup !== "undefined" &&
                formerror?.selectedGroup !== "" && (
                  <span className="small error color-danger">
                    {formerror?.selectedGroup}
                  </span>
                )}
            </div>
            <div className="sectiontitlenew">Step 2 - Message type</div>
            <div className="">
              <SingleSelect
                label="Select Type"
                value={
                  formdata?.selectedMessageType
                    ? formdata?.selectedMessageType
                    : ""
                }
                className={`field`}
                name={"selectedMessageType"}
                onChange={(e) => {
                  handlefieldChangenew("selectedMessageType", e.target.value);
                }}
                options={[...messageTypeList]}
              />
              {typeof formerror?.selectedMessageType !== "undefined" &&
                formerror?.selectedMessageType !== "" && (
                  <span className="small error color-danger">
                    {formerror?.selectedMessageType}
                  </span>
                )}
            </div>
          </Grid>
          <Grid item sm={12} md={9}>
            <div className="sectionBox radius nospacing bg-gray p-15 h-stretch">
              <h4 className="m-0 mb-12">
                Audience ({allaudianceListName.length})
              </h4>
              <Typography variant="subtitle1">
                {allaudianceListName.join(", ")}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container className="message_container">
          <Grid item sm={12} md={12}>
            <TextField
              value={formdata?.message ? formdata?.message : ""}
              onChange={(e) => handlefieldChangenewm("message", e.target.value)}
              fullWidth
              id="message"
              label="Type Your message here"
              multiline
              rows={4}
              // defaultValue="Type Your message here"
              variant="outlined"
            />
            {typeof formerror?.message !== "undefined" &&
              formerror?.message !== "" && (
                <span className="small error color-danger">
                  {formerror?.message}
                </span>
              )}
          </Grid>
        </Grid>
        <Grid container className="buttonwrap mv-15">
          <Grid item sm={12} md={12}>
            <Button
              variant="contained"
              color="primary"
              className="themebutton flatbutton mr-12"
              onClick={onSaveNotification}
            >
              Save
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="themebutton flatbutton mr-12"
              onClick={clearformdata}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              className="themebutton flatbutton "
              onClick={() => {
                confirm(
                  "Are you sure you want to cancel , this wont be saved/ send ?"
                ).then(() => {
                  history("/notifications/manage");
                });
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default CreateNotification;
